<template>
  <div class="card card-profile">
    <!---->
    <div class="card-body">
      <!----><!---->
      <div class="profile-image-wrapper">
        <div
          class="profile-image"
          :set="random = getRandomColor(value.name )"
        >
          <b-avatar
            button
            size="100"
            :variant="random.color"
            :text="random.character"
            class="align-baseline"
          />
        </div>
      </div>
      <span
        class="position-absolute cursor-pointer text-primary"
        style="right:20px; top:10px;"
        @click="$router.push({name:'team-setting',params:{uuid:team.uuid}})"
      >
        <feather-icon
          icon="SettingsIcon"
          size="25"
        />
      </span>
      <h3>{{ team.name }}</h3>
      <h6
        class="text-muted text-ellipsis"
        :title="value.address"
      >
        {{ team.address }}
      </h6>
      <hr class="mb-2">
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <h6 class="text-muted font-weight-bolder">
            Total Members
          </h6>
          <h3 class="mb-0">
            {{ totalMember }}
          </h3>
        </div>
        <div>
          <h6 class="text-muted font-weight-bolder">
            Projects
          </h6>
          <h3 class="mb-0">
            {{ team.project.name }}
          </h3>
        </div>
      </div>
      <div class="d-flex pt-2 justify-content-center">
        <b-button
          variant="outline-success"
          @click="showModal = true"
        >
          <feather-icon icon="InfoIcon" /> Add Member
        </b-button>
        <!-- <b-button
          variant="outline-primary"
          class="ml-2"
          @click="$emit('editCompany',value)"
        >
          <feather-icon icon="EditIcon" /> Edit
        </b-button> -->

        <!-- <b-button
          variant="outline-danger"
          class="ml-2"
          @click="$emit('destroyCompany',value)"
        >
          <feather-icon icon="TrashIcon" /> Delete
        </b-button> -->

      </div>

    </div>
    <add-team
      :show="showModal"
      :team-info="value"
      :members="value.users.data"
      @close="showModal = false"
      @updateTeamCount="(total) => totalMember = total"
    />
  </div>
</template>
<script>
import { BAvatar, BButton } from 'bootstrap-vue'
import AddTeam from './addTeam.vue'

export default {
  components: {
    BAvatar,
    BButton,
    AddTeam,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
      team: this.value,
      totalMember: this.value.users.total,
    }
  },
}
</script>
<style scoped>
.text-ellipsis{
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
